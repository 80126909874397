@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root nav div {
  margin: 0;
  max-width: 100%;
}

#root nav {
  display: flex;
  justify-content: center;
  margin: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Changing the text color, in case you need it */
input:-webkit-autofill {
  -webkit-text-fill-color: black !important;
}

/* Hide scrollbar for the entire project */
::-webkit-scrollbar {
  width: 0;
  /* For Chrome, Safari, and Opera */
}

/* Hide scrollbar for the entire project */
::-moz-scrollbar {
  width: 0;
  /* For Firefox */
}

/* Hide scrollbar for the entire project */
body {
  scrollbar-width: none;
  /* For Firefox */
}