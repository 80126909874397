/*======================
    404 page
=======================*/

.page_404 {
  background: white;
  text-align: center;
  /* color:#575757 */
}

.fontSize {
  font-size: 14px !important;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  /* margin-top: 25px; */
}

.four_zero_four_bg h1 {
  font-size: 80px;
  transform: translateY(-30px);
}

.four_zero_four_bg h3 {
  font-size: 50px;
}

.contant_box_404 {
  font-size: 30px;
  color: white;
}

.not-avaible {
  font-size: 17px;
}

.supportSection {
  font-size: 10px !important;
}

@media screen and (max-width: 480px) {
  .supportSection {
    font-size: 10px !important;
  }

  .four_zero_four_bg {
    height: 200px;
    background-position: top;
  }

  .four_zero_four_bg h1 {
    font-size: 40px;
    transform: translateY(-10px);
  }

  .four_zero_four_bg h3 {
    font-size: 30px;
  }

  .contant_box_404 {
    font-size: 20px;
  }

  .not-avaible {
    font-size: 14px;
  }

  .four_zero_four_bg {
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.link_404 {
  color: black !important;
  padding: 10px 20px;
  background: #e6e9e6;
  margin: 20px 0;
  display: none;
}

.w {
  color: white !important;
  /* // */
}

.hauto {
  height: 330px !important;
}

.header404 {
  margin-top: 5rem;
}

